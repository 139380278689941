<template>
  <div class="space-y-5">
    <div class="flex items-center justify-between flex-row-reverse lg:flex-row">
      <h1 class="page_title">{{ $route.meta.title }}</h1>

      <div v-if="is_API_loaded" class="flex items-center space-x-4">
        <router-link :to="{ name: 'blogs' }">
          <vs-button size="large" transparent><span class="font-medium">管理部落格</span></vs-button>
        </router-link>

        <router-link :to="{ name: 'comments' }">
          <vs-button size="large" transparent><span class="font-medium">管理留言</span></vs-button>
        </router-link>

        <router-link :to="{ name: 'new-post' }">
          <vs-button size="large"><span class="font-medium">新增文章</span></vs-button>
        </router-link>
      </div>

      <!-- 骨架屏 -->
      <skeleton v-else width="82px" />
    </div>

    <main v-if="is_API_loaded" class="bg-white rounded-2xl w-full pt-4">
      <section class="flex px-4 mb-2 flex-wrap sm:flex-nowrap sm:space-x-3 space-y-3 sm:space-y-0">
        <vs-input class="w-full" v-model="searchTitle" @blur="search" @keypress.enter="search" placeholder="搜尋文章" />
        <div class="w-full sm:w-auto flex items-center justify-between space-x-3">
          <vs-select v-if="blogs.length" style="width: 200px" placeholder="篩選部落格" v-model="filteredBlog" @change="blogOptions">
            <vs-option label="全部" value="''">全部</vs-option>
            <vs-option v-for="blog in blogs" :key="blog.sbno" :label="blog.title" :value="blog.sbno">{{ blog.title }}</vs-option>
          </vs-select>
          <vs-select style="width: 200px" multiple filter placeholder="篩選狀態" v-model="filtered" @change="statusOptions">
            <vs-option label="公開" value="1">已公開</vs-option>
            <vs-option label="隱藏" value="0">已隱藏</vs-option>
          </vs-select>
        </div>
      </section>

      <section class="text-sm overflow-x-auto">
        <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-min sm:w-full">
          <vs-checkbox v-if="indeterminate" indeterminate v-model="indeterminate" class="checkbox_section" @click="indeterminateHandleClick"
            ><span class="text-xs">已選取 {{ selected.length }} 筆</span></vs-checkbox
          >

          <vs-checkbox v-else class="checkbox_section" v-model="selectAll" @click="checkAllHandleClick">
            <span v-if="selectAll" class="text-xs">已選取 {{ selected.length }} 筆</span>
            <span v-else class="text-xs">共 {{ posts.length }} 筆</span>
          </vs-checkbox>

          <transition name="fade">
            <div v-if="indeterminate || selectAll" class="title_section" key="1">
              <div class="w-max border border-gray-border rounded-xl grid grid-flow-col h-8 divide-x divide-gray-border overflow-hidden">
                <button @click="batchPublic(1)" class="px-4 hover:bg-gray-background font-medium">公開</button>
                <button @click="batchPublic(0)" class="px-4 hover:bg-gray-background font-medium">隱藏</button>
                <button @click="deletePosts_dialog = true" class="px-4 hover:bg-gray-background font-medium">刪除</button>
              </div>
            </div>
            <div v-else class="title_section" key="2">
              <span class="title ml-14">文章標題</span>
              <span class="w-36">文章狀態</span>
              <span style="width: 187px">最後修改</span>
            </div>
          </transition>
        </div>

        <!-- 列表 -->
        <div class="flex flex-col divide-y divide-gray-background text-gray-text_light w-min sm:w-full">
          <div v-for="item in posts" :key="item.sbpno">
            <router-link
              :to="{
                name: 'edit-post',
                params: {
                  id: item.sbpno,
                },
              }"
              class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group"
            >
              <vs-checkbox class="checkbox_section" @click.stop="checkBoxHandleClick" v-model="selected" :val="item.sbpno"></vs-checkbox>
              <div class="title_section">
                <div class="w-14">
                  <div class="w-10 h-10 items-center justify-center flex border border-gray-border rounded-lg overflow-hidden">
                    <img v-if="item.img" :src="item.img" class="w-full h-full object-cover" />
                    <svg-icon v-else icon-class="image" className="gray-border sm"></svg-icon>
                  </div>
                </div>
                <div class="flex flex-col title">
                  <span class="text-limit-1">{{ item.title }}</span>
                  <span class="text-xs text-gray-400">—— {{ item.btitle }}</span>
                </div>
                <div class="w-36">
                  <span v-if="item.isrtime" class="bg-primary text-white rounded-full px-3 py-1">已公開</span>
                  <span v-else-if="!item.isrtime && isSchedule(item.rtime)" class="bg-warning text-white rounded-full px-3 py-1"
                    >排程中</span
                  >
                  <span v-else class="bg-gray-background text-gray-text_light rounded-full px-3 py-1">已隱藏</span>
                </div>
                <span style="width: 187px">{{ item.udate }}</span>
              </div>

              <!-- 預覽分頁按鈕 -->
              <a
                @click.stop=""
                :href="item.mpages"
                target="_blank"
                class="
                  hidden
                  lg:block
                  absolute
                  right-3
                  top-1/2
                  transform
                  -translate-y-1/2
                  transition-all
                  opacity-0
                  hover:bg-gray-divide
                  group-hover:opacity-100 group-hover:visible
                  invisible
                  rounded-lg
                "
              >
                <svg-icon icon-class="eye_view" className="primary xl"></svg-icon>
              </a>

              <div
                v-if="item.isread_num > 0"
                @click.stop=""
                class="absolute left-16 top-1/2 transform -translate-y-1/2 hover:bg-gray-divide rounded-lg"
              >
                <vs-tooltip danger>
                  <svg-icon icon-class="message" className="danger xl"></svg-icon>
                  <template #tooltip>此文章有 {{ item.isread_num }} 筆未讀留言</template>
                </vs-tooltip>
              </div>
            </router-link>
          </div>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>

    <!-- pagination -->
    <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.tpages" />
      <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 筆</span>
    </div>

    <!-- 刪除文章 dialog -->
    <vs-dialog overflow-hidden blur v-model="deletePosts_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ selected.length }} 筆文章？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的文章無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deletePosts_dialog = false">取消</vs-button>
          <vs-button @click="bathDelete" size="large" transparent color="danger" ref="deletePostBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'posts',
  data() {
    return {
      is_API_loaded: false,
      currentPage: 1, // 當前頁數
      searchTitle: '', // 搜尋標題input
      searchTitleBak: '',
      filtered: [], // 篩選發布狀態
      filteredBlog: '', // 篩選部落格,部落格碼
      posts: [], // 文章列表
      blogs: [], // 部落格列表
      pagination: [], // 頁數資訊
      selected: [],
      selectAll: false,
      indeterminate: false,
      deletePosts_dialog: false,
      defaultQuery: {}, // 預設$route.query
    }
  },
  computed: {
    // 篩選發布狀態轉數字
    _isrtime() {
      let filtered = this.filtered

      if (filtered.includes('1') && filtered.includes('0')) {
        return -1
      } else if (filtered.includes('1')) {
        return 1
      } else if (filtered.includes('0')) {
        return 0
      } else {
        return -1
      }
    },
    // 把多選的checkbox陣列轉字串
    _selectedToStr() {
      return this.selected.toString()
    },
  },
  async created() {
    let query = this.$route.query
    this.defaultQuery = query

    this.searchTitle = query.search || ''
    this.filteredBlog = query.blog || ''
    this.filtered = query.status?.split(',') || []
    this.currentPage = parseInt(query.page) || 1

    // 讀取初始資料
    const res = await Promise.all([this.getPostsList(), this.getBlogsList()])

    // res會是陣列，若兩個都為true，關閉loading
    if (res.every((e) => e === true)) {
      this.is_API_loaded = true
    }
  },
  methods: {
    // 2.5.6 文章列表-讀取
    getPostsList(arr) {
      // 深拷貝
      let routeQuery = JSON.parse(JSON.stringify(this.$route.query))

      // 新增或覆蓋進routeQuery物件
      if (arr && arr.length > 0) {
        arr.forEach((item) => {
          routeQuery[item[0]] = item[1]
        })
      }

      // 迴圈，若物件中有空值就刪除
      for (let key in routeQuery) {
        // 若值為空，刪除。
        if (routeQuery[key] === '' || routeQuery[key] === -99) {
          delete routeQuery[key]
        }
      }

      // 判斷物件是否一樣
      function objCompare(obj1, obj2) {
        const obj1_keys = Object.keys(obj1)
        const obj2_keys = Object.keys(obj2)
        if (obj1_keys.length !== obj2_keys.length) {
          return false
        }
        for (let key of obj1_keys) {
          if (obj1[key] !== obj2[key]) {
            return false
          }
        }
        return true
      }

      if (!objCompare(routeQuery, this.defaultQuery)) {
        // 不一樣，重整query
        this.$router.replace({ query: routeQuery })
        this.defaultQuery = routeQuery
      }

      return this.$axios({
        url: 'front/pages/blogs/getPagesList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          isrtime: this._isrtime,
          page: routeQuery.page || 1,
          title: routeQuery?.search || '',
          sbno: routeQuery?.blog || '',
        },
      }).then((res) => {
        if (res.data.Success) {
          this.posts = res.data.Data
          this.pagination = res.data.Page

          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.5.1 部落格列表-讀取
    getBlogsList() {
      return this.$axios({
        url: 'front/pages/blogs/getBlogsList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: -1,
          title: '',
        },
      }).then((res) => {
        if (res.data.Success) {
          this.blogs = res.data.Data
          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },

    // 判斷是不是排程中
    isSchedule(rtime) {
      let time = new Date(rtime.replace(new RegExp('-', 'gm'), '/')).getTime()

      // 時間若小於 2099/12/31 23:59:00
      if (time < 4102415940000) {
        return true
      } else {
        return false
      }
    },
    // 改變頁籤時觸發
    changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false
      this.getPostsList([['page', this.currentPage]])
    },
    // 篩選狀態時觸發
    statusOptions(e) {
      this.getPostsList([
        ['page', 1],
        ['status', this.filtered.toString()],
      ])
    },
    // 篩選部落格時觸發
    blogOptions(e) {
      this.getPostsList([
        ['page', 1],
        ['blog', this.filteredBlog],
      ])
    },
    // 篩選標題時觸發
    search(e) {
      // 若一樣，不執行搜尋
      if (this.searchTitle === this.searchTitleBak) return

      this.getPostsList([
        ['page', 1],
        ['search', this.searchTitle],
      ])

      this.currentPage = 1
      this.searchTitleBak = this.searchTitle
    },
    // 批次操作 公開/隱藏
    batchPublic(isrtime) {
      this.$axios({
        url: 'front/pages/blogs/uPagesRtime.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sbpno: this._selectedToStr,
          isrtime: isrtime, // 0 隱藏 1 公開
        },
      }).then((res) => {
        if (res.data.Success) {
          this.getPostsList([['page', this.currentPage]])

          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 批次操作 刪除
    bathDelete() {
      const loading = this.$vs.loading({
        target: this.$refs.deletePostBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/pages/blogs/dPages.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          sbpno: this._selectedToStr,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.selected = []
          this.indeterminate = false
          this.selectAll = false
          loading.close() // 關閉按鈕loading
          this.deletePosts_dialog = false // 關閉dialog
          this.getPostsList([['page', 1]])

          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    checkBoxHandleClick(e) {
      setTimeout(() => {
        if (this.selected.length > 0 && this.selected.length < this.posts.length) {
          // 勾選卻沒有全選時
          this.indeterminate = true
        } else if (this.selected.length === this.posts.length) {
          // 全選時
          this.indeterminate = false
          this.$nextTick(() => {
            this.selectAll = true
          })
        } else {
          // 沒選時
          this.indeterminate = false
          this.selectAll = false
        }
      }, 0)
    },
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        for (let i = 0; i < this.posts.length; i++) {
          this.selected.push(this.posts[i].sbpno)
        }
      } else {
        this.selected = []
      }
    },
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
  },
}
</script>
<style lang="scss" scoped>
.checkbox_section {
  @apply w-28 flex-shrink-0;
}

.title_section {
  @apply flex items-center w-min sm:w-full;
  .title {
    @apply w-48 sm:flex-grow;
  }
}
</style>
